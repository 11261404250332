import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import {
  SwalFailed
} from './../plugins/sweetalert2'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '*',
      name: 'notfound',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/NotFoundPage.vue'),
      meta: {
        title: 'Page Not Found',
        loginRequired: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/LoginPage.vue'),
      meta: {
        title: 'Zonasea Login Page',
        guest: true
      }
    },
    {
      path: "/auth/signin/:token",
      name: "auth-signin",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/auth/SigninPage.vue"),
      meta: {
        title: "Zonasea Signin Page",
        guest: true
      }
    },
    {
      path: "/auth/wizard/company/:token",
      name: "auth-wizard-company",
      component: () => import(/* webpackChunkName: "group-foo" */ "@/pages/auth/CreateCompanyPage.vue"),
      meta: {
        title: "Zonasea Create Company Page",
        guest: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/RegisterPage.vue'),
      meta: {
        title: 'Zonasea Sign Up',
        guest: true
      }
    },
    {
      path: '/resend-email-verification/:email',
      name: 'resend-email-verification',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/ResendEmailVerification.vue'),
      meta: {
        title: 'Zonasea Sign Up',
        guest: true
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/ResetPasswordPage.vue'),
      meta: {
        title: 'Zonasea Reset Password',
        guest: true
      }
    },
    {
      path: '/app',
      name: 'app',
      redirect: 'app/shipment',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/MainApp.vue'),
      meta: {
        title: 'Zonasea Cargo Owner',
        loginRequired: true
      },
      children: [
        {
          path: 'chat',
          name: 'chat',
          component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/main/Chat.vue'),
          meta: {
            title: 'Zonasea Cargo Owner',
            loginRequired: true
          }
        },
        {
          path: 'port',
          name: 'port',
          component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/main/Port.vue'),
          meta: {
            title: 'Zonasea Cargo Owner',
            loginRequired: true
          }
        },
        {
          path: 'shipment',
          name: 'shipment',
          component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/main/Shipment.vue'),
          meta: {
            title: 'Zonasea Cargo Owner',
            loginRequired: true
          }
        }
      ]
    },
    {
      path: '/account',
      name: 'account',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/account/Account'),
      meta: {
        title: 'Zonasea Profile',
        loginRequired: true
      },
      children: [
        {
          path: 'profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/account/Profile.vue'),
          meta: {
            title: 'Zonasea Profile',
            loginRequired: true
          }
        }
      ]
    },
    {
      path: '/public/shipment',
      name: 'public',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/TrackApp.vue'),
      meta: {
        title: 'Zonasea Profile',
        loginRequired: false
      }
    },
    {
      path: '/account/api',
      name: 'api',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/account/ApiKeys.vue'),
      meta: {
        title: 'Zonasea Manage API Keys',
        loginRequired: true
      }
    },
    {
      path: '/account/api/test',
      name: 'api-test',
      component: () => import(/* webpackChunkName: "group-foo" */ '@/pages/account/ApiTest.vue'),
      meta: {
        title: 'Zonasea Test API',
        loginRequired: true
      }
    }
  ]
})

router.afterEach (() => {
  const appLoading = document.getElementById ('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

})

router.beforeEach ((to, from, next) => {
  window.scrollTo(0, 0)
  document.title = to.meta.title

  if (to.matched.some(record => record.meta.loginRequired)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('token') && !store.getters['moduleAuth/loggedIn']) {
      next({ name: 'login' })

      return
    }

    if (store.getters['moduleAuth/GET_USER_DATA'].roles && store.getters['moduleAuth/GET_USER_DATA'].roles.id === 3) {
      SwalFailed(
        `Mohon maaf role ${store.getters['moduleAuth/GET_USER_DATA'].roles.name} tidak dapat mengakses halaman ini`,
        () => {
          store.dispatch('moduleAuth/LOGOUT')
        }
      )

      next()

      return
    }

    if (from.name === 'port') store.commit('modulePort/SET_PORT_MARKER')
    if (from.name === 'shipment') store.commit('moduleShipment/SET_DATA_SHIPMENT', [])
    if (from.name === 'chat' || from.name === 'shipment') {
      store.commit('moduleShipment/SET_PANEL_OFFER', false)
      store.commit('moduleChat/SET_PANEL_CHAT', false)
    }

    store.dispatch('moduleChat/LOAD_NOTIF_INBOX')
    store.commit('RESET_BOUND')

    next()

    return
  }
  
  if (to.matched.some(record => record.meta.guest)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters['moduleAuth/loggedIn'] && store.getters['moduleAuth/GET_USER_DATA'].company && localStorage.getItem ('token')) {
      next({ name: 'app' })

      return
    }
  }

  next()
})


export default router
