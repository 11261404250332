import Vue from 'vue'
import Vuex from 'vuex'
import API from '@/plugins/axios'

import { icon } from 'leaflet'

import moduleAuth from './auth'
import moduleChat from './chat'

import moduleShipment from './transaction/shipment'
import modulePort from './master/port'

Vue.use(Vuex)

const isMobile = window.outerWidth <= 768

const store = {
    modules: {
      moduleAuth,
      moduleChat,

      moduleShipment,
      modulePort
   },
   state: {
      isLoading: false,
      refreshLiveTrack: new Date(),

      showLeftbar: isMobile ? false : true,
      activePanelCargoList: false,
      modalDetailCargo: false,
      shipmentList: [],
      currentZoom: 0,
      currentCenter: [0, 0],
      currentVessel: null,
      currentBounds: [[14.43468021529728, 141.06445312500003], [-17.602139123350838, 77.78320312500001]],
      paddingTopLeft: [isMobile ? 0 : 250, 150], // [left, top]
      paddingBottomRight: [isMobile ? 0 : 250, 50], // [right, bottom]
      polyline: {
         show: false,
         latlngs: [],
         color: '#1c344f',
         dash: '4'
      },
      destinationMarker: {
         latlngs: [],
         show: false,
         name: ''
      },
      fromMarker: {
         data: {},
         latlngs: [],
         show: false,
         name: ''
      },
      portMarkers: {
         data: {},
         latlngs: [],
         show: false,
         name: ''
      },
      adsMarkers: {
         data: {},
         show: false,
         name:''
      },
      mapOptions: {
         zoomSnap: 0.25,
         zoomDelta: 0.25,
         zoomControl: false
      },
      tileProviders: [
         {
               name: 'Default',
               visible: false,
               url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
               attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
         },
         {
               name: 'MapBox',
               visible: true,
               url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZmF0aGFsZmF0aDMwIiwiYSI6ImNrM3NlcXB5NTA1cGszbW80ZDJnNTV3azEifQ.b_7rn9WvpIqbhXAuYZQlYw',
               attribution:'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
         }
      ],
      icon: icon({
         iconUrl: '/images/icon_marker_map.png',
         iconSize: [32, 37],
         iconAnchor: [16, 37]
      }),
      shipIcon: icon({
         iconUrl: '/images/vessel-pin.png',
         iconSize: [32, 37],
         iconAnchor: [16, 37]
      }),
      currentVesselTrail : null,
      trailHistory : null,
      trailHistoryLabel : null,

      showOfferingListDocuments : null,
      showOfferingStatus : null,
      showManifest : null,
      showManifestLogs : null,
   },
   getters: {
      GET_LOADING: state =>  state.isLoading
   },
   mutations: {
      SET_SHOW_MANIFEST (state, payload) {
         state.showManifest = payload
      },
      SET_SHOW_MANIFEST_LOGS (state, payload) {
         state.showManifestLogs = payload
      },
      SET_SHOW_OFFERING_LIST_DOCUMENTS (state, payload) {
         state.showOfferingListDocuments = payload
      },
      SET_SHOW_OFFERING_STATUS (state, payload) {
         state.showOfferingStatus = payload
      },
      SET_TRAIL_HISTORY (state, payload) {
         state.trailHistory = payload
      },
      SET_TRAIL_HISTORY_LABEL (state, payload) {
            state.trailHistoryLabel = payload
      },
      SET_CURRENT_VESSEL_TRAIL (state, payload) {
         state.currentVesselTrail = payload
      },
      SET_LOADING (state) {
         state.isLoading = !state.isLoading
      },
      SET_REFRESH_LIVE_TRACK (state, payload) {
         state.refreshLiveTrack = payload
      },
      SET_MODAL_DETAIL_CARGO (state, payload) {
         state.modalDetailCargo = payload
      },
      SET_PANEL_CARGO_LIST (state, payload) {
         state.activePanelCargoList = payload
      },
      TOOGLE_NAV (state) {
         state.showLeftbar = !state.showLeftbar
      },

      ZOOM_UPDATED (state, zoom) {
         state.currentZoom = zoom
      },
      CENTER_UPDATED (state, center) {
         state.currentCenter = center
      },
      SELECT_VESSEL (state, vessel) {
         state.currentVessel = vessel
      },
      RESET_BOUND (state) {
         state.currentBounds = [[14.43468021529728, 141.06445312500003], [-17.602139123350838, 77.78320312500001]]
      },
      SET_BOUND(state, bounds){
         state.currentBounds = bounds
      },
      SET_ADS_MARKER (state, payload) {
         if (payload) {
               state.adsMarkers.data = payload
               state.adsMarkers.show = true
         } else {
               state.adsMarkers.data = payload
               state.adsMarkers.show = false
         }
      },
      SET_FROM_MARKER (state, payload) {
         state.fromMarker.max = false;
         state.fromMarker.min = true;
         if (payload) {
            state.fromMarker.latlngs = [payload.lat, payload.long]
            state.fromMarker.data = payload
            state.currentBounds = [[payload.lat, payload.long], [payload.lat, payload.long]]
            state.fromMarker.show = true

            if(typeof payload.name !== 'undefined'){
               state.fromMarker.name = payload.name
            }
         } else {
            state.fromMarker.min = false;
            state.fromMarker.data = []
            state.fromMarker.data = {}
            state.fromMarker.show = false
            state.fromMarker.name = null
         }

         console.log(state.fromMarker)
      },
      SET_PORT_MARKERS (state, payload) {
         state.fromMarker.max = false;
         state.fromMarker.min = true;
         if (payload) {
            state.portMarkers.latlngs = [payload.lat, payload.long]
            state.portMarkers.data = payload
            state.currentBounds = [[payload.lat, payload.long], [payload.lat, payload.long]]
            state.portMarkers.show = true
         } else {
            state.portMarkers.data = []
            state.portMarkers.data = {}
            state.portMarkers.show = false
         }
      }

   },
   actions: {
      RESET_ROUTE ({state, commit}) {
         commit('RESET_BOUND')
         commit('TOOGLE_NAV')
         state.polyline.show = false
         state.destinationMarker.show = false
         state.fromMarker.show = false
         state.fromMarker.max = false
         state.fromMarker.min = false

         state.fromMarker.latlngs = []
         state.destinationMarker.latlngs = []
     },
      FIND_ROUTE ({state}, data) {
         state.fromMarker.min = false
         state.fromMarker.max = true
         state.polyline.latlngs = [ data.fromLatLng, data.destLatLng ] // set polyline garis lurus mulai dari latlngs port start hingga port destination

         state.fromMarker.latlngs = data.fromLatLng // set marker latlng port, berdasarkan latlng port start cargo
         state.fromMarker.data = data
         state.fromMarker.name = data.port_from_name

         state.destinationMarker.latlngs = data.destLatLng // set marker latlng port, berdasarkan latlng port destination cargo
         state.destinationMarker.name = data.port_destination_name

         state.polyline.show = true // menampilkan polyline
         state.destinationMarker.show = true // menampilkan marker destination
         state.fromMarker.show = true // menampilkan marker destination
         state.showLeftbar = false

         state.currentBounds = [data.fromLatLng, data.destLatLng] // set bound untuk menyesuaikan latlng port start and latlngs port destination

      },
      LOAD_FROM_MARKER ({commit}, payload) {
         if (payload) {
            commit('SET_FROM_MARKER', payload)
         } else {
            commit('SET_FROM_MARKER')
            console.log(payload)
            commit('RESET_BOUND')
         }
      },
      LOAD_PORT_MARKERS ({commit}, payload) {
         if (payload) {
            commit('SET_FROM_MARKER', payload)
         } else {
            commit('SET_FROM_MARKER')
            console.log(payload)
            commit('RESET_BOUND')
         }
      },
      LOAD_ADS ({commit}, payload) {
         if (payload) {
             commit('SET_LOADING')
             const params = {
                 ads_category: payload.id
             }
             return new Promise ((resolve,reject) => {
                 API.get('publicity/product/search', {params})
                 .then(res => {
                     const data = res.data.data
                     commit('SET_ADS_MARKER', data)
                     commit('RESET_BOUND')
                     resolve(res)
                 })
                 .catch((err) => {
                     reject(err)
                 })
                 .finally(() => {
                     commit('SET_LOADING')
                 })
             })
             
         } else {
             commit('SET_ADS_MARKER')
         }
     }
   }
}
export default new Vuex.Store(store)
