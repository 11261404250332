import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './routes/router'
import BootstrapVue from 'bootstrap-vue'
// import VCalendar from 'v-calendar';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import Echo from 'laravel-echo'
import Vuelidate from 'vuelidate'
import './plugins/globals'
import vSelect from 'vue-select'

window.Pusher = require('pusher-js')
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    // key: 'e7d6356ffac0cc1d1c29',
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    // cluster: 'ap1',
    encrypted: false,
    authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
    forceTLS: true,
    auth: {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }
})
Vue.component('v-select', vSelect)
Vue.use(Vuelidate)

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
localize('en', en)

Vue.use(BootstrapVue)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
