import SW from 'sweetalert2'

const confirmColor = '#1074bf'
const cancelColor = '#e63946'

function Swal (msg, callback) {
    SW.fire({
        position: 'center',
        icon: 'success',
        title: msg.title || 'Good Job!',
        text: msg.text || msg,
        showConfirmButton: false,
        timer: 1000,
        didClose () {
            if (callback) callback ()
        }
    })
}
function SwalInfo (msg, callback) {
    SW.fire({
        title: msg.title || 'Oops...',
        text: msg.text || msg,
        icon: 'info',
        allowOutsideClick: false,
        confirmButtonColor: confirmColor,
        didClose () {
            if (callback) callback ()
        }
    })
}

function SwalSuccess (msg, callback) {
    SW.fire({
        title: msg.title || 'Good Job!',
        text: msg.text || msg,
        icon: 'success',
        allowOutsideClick: false,
        confirmButtonColor: confirmColor,
        didClose () {
            if (callback) callback ()
        }
    })
}

function SwalWarning (msg, callback) {
    SW.fire({
        title: msg.title || 'Oops...',
        text: msg.text || msg,
        icon: 'warning',
        allowOutsideClick: false,
        confirmButtonColor: confirmColor,
        didClose () {
            if (callback) callback ()
        }
    })
}

function SwalFailed (msg, callback) {
    SW.fire({
        title: msg.title || 'Oops...',
        text: msg.text || msg,
        icon: 'error',
        allowOutsideClick: false,
        confirmButtonColor: confirmColor,
        didClose () {
            if (callback) callback ()
        }
    })
}

function SwalConfirm (msg, callback) {
    SW.fire({
        title: msg.title || 'Wait..',
        html: msg.text || msg || 'Are you sure ?',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: confirmColor,
        cancelButtonColor: cancelColor,
        confirmButtonText: msg.confirmText || 'Yes',
        cancelButtonText: msg.cancelText || 'No'
    }).then((result) => {
        if (result.isConfirmed) {
            if (callback) callback ()
        }
    })
}

export {
    Swal,
    SwalConfirm,
    SwalFailed,
    SwalInfo,
    SwalSuccess,
    SwalWarning
}
