<template>
  <div>
    <div id="header">
      <div class="header-m">
        <div class="header-m-left">
          <router-link
            :to="'/app'"
            class="text-decoration-none"
          >
            <i class="fa fa-home fa-lg c-pointer" />
          </router-link>
        </div>
        <div class="header-m-right">
          <notifications />
          <div
            v-click-outside="hide"
            class="header-m-user"
          >
            <div
              class="c-pointer"
              @click="toggle"
            >
              <img
                src="\images\image_user.png"
                class="profile-menu"
              >
            </div>
            <div
              v-show="isActiveBtnUser"
              class="action-user"
            >
              <div class="row">
                <div class="col-5">
                  <img
                    src="\images\image_user.png"
                    class="action-user-img"
                  >
                </div>
                <div class="col-7 pl-0 col-content">
                  <div>
                  <div class="content-close">
                    <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="hide"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>       
                  </div>
                    <div class="content-name">
                      {{ myProfile.name }}
                    </div>
                    <div class="content-email">
                      {{ myProfile.email }}
                    </div>
                  </div>
                  <router-link :to="'/account/profile'">
                    <button
                      class="btn btn-primary btn-sm btn-block mx-0"
                      @click="hide"
                    >
                      View Detail
                    </button>
                  </router-link>
                  <router-link class="text-dark mt-2" :to="'/account/api'">
                  <button class="content-link"  @click="hide">
                    Manage API Keys
                  </button>
                  </router-link>
                  <router-link class="text-dark mt-2" :to="'/account/api/test'"  @click="hide">
                    <button class="content-link"  @click="hide">
                    Test API
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <div class="list-group list-group-flush">
                    <a
                      href="#"
                      class="list-group-item list-group-item-action"
                      @click="userLogout"
                    >Sign Out</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, numeric} from 'vuelidate/lib/validators'
import $ from 'jquery'
import request_api from '../core/request_api'
import notifications from './Notifications'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'cHeader',
  components: {
    notifications
  },
  data () {
    return {
      // Menu Header
      isActiveBtnSearch: false,
      isActiveBtnNotif: false,
      isActiveBtnUser: false,
      isSetting: false,

      showTabProfile: true,
      showTabPassword: false,
      passwordFieldType: 'password',
      iconShowPassword: 'fa fa-fw fa-eye',
      updatePassword: {
        old: '',
        new: '',
        confirm: ''
      },
      myProfile: []
    }
  },
  validations:{
    myProfile:{
      detail:{
        phone: {
          required,
          numeric
        }
      },
      name:{
        required
      }
    }
  },
  created () {
    this.load_profile()
  },
  methods: {
    outside () {
      if (this.isActiveBtnNotif === true) {
        $('.action-notif').css('display', 'none')
        this.isActiveBtnNotif = false
      } else if (this.isActiveBtnUser === true) {
        $('.action-user').css('display', 'none')
        this.isActiveBtnUser = false
      }
    },

    toggle () {
      this.isActiveBtnUser = !this.isActiveBtnUser
    },

    hide () {
      this.isActiveBtnUser = false
    },

    userLogout () {
      localStorage.clear()
      window.document.location = '/'
    },

    load_profile () {
      const vi = this
      const endpoint = 'user/profile'
      request_api()
        .get(endpoint)
        .then((response) => {
          const meta = response.data
          const data = meta.data
          if (meta.code === 200) {
            vi.myProfile = data
            vi.isSetting = true
          }
        })
        .catch((error) => {
          console.log(error.response)
        })
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style></style>
