import API from '../plugins/axios'
import router from '../routes/router'
import { SwalConfirm, SwalFailed, SwalWarning, SwalSuccess } from '../plugins/sweetalert2'

const moduleAuth = {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || null,
    user_data: (localStorage.getItem ('userData')  === null) ? Object : JSON.parse(localStorage.getItem ('userData')),
    loadingLogin: false
  },
  getters: {
    loggedIn: state => !!state.token,
    GET_USER_DATA: state => state.user_data,
    GET_LOADING_LOGIN: state => state.loadingLogin
  },
  mutations: {
    SET_USER_DATA (state, payload) {
      state.user_data = payload
      state.token = payload.token.access_token

      localStorage.setItem ('userData', JSON.stringify(payload.data))
      localStorage.setItem ('token', payload.token.access_token)
    },
    SET_LOADING_LOGIN (state, payload) {
      state.loadingLogin = payload
    }
  },
  actions: {
    async LOGIN ({dispatch, commit}, payload) {
      const params = payload
      commit('SET_LOADING_LOGIN', true)
      await API.post('auth/login', params)
        .then(async (res) => {
            const data = res.data.data
            await commit('SET_USER_DATA', data)
            window.location = '/app'
        })
        .catch((err) => {
            if (typeof err.response === 'undefined') {
                SwalFailed(err.response.statusText)
            } else if (err.response.data.message === "Akun Anda belum diaktifkan. Silakan periksa email Anda untuk melakukan aktivasi akun.") {
                SwalConfirm({text: err.response.data.message, confirmText: 'Resend Email'}, () => { dispatch('RESEND_EMAIL', payload.user) })
            } else {
                SwalWarning(err.response.data.message)
            }
        })
        .finally(() => {
            commit('SET_LOADING_LOGIN', false)
        })
    },

    async LOGOUT () {
        await API.post('auth/logout')
        .then(async () => {
            localStorage.clear()
            router.push({ name: 'login' }).catch(() => {})
        })
        .catch(() => {
        })

    },

    async RESEND_EMAIL ({commit}, payload) {
        const params = {
            user: payload
        }
        commit('SET_LOADING_LOGIN', true)
        const endpoint = 'registration/resend'
        API.patch(endpoint, params)
            .then((res) => {
                const meta = res.data
                if (meta.code === 200) {
                    SwalSuccess ('Silahkan cek email anda!', () => {
                        this.alerting = this.user_email
                    })
                }
            })
            .catch((err) => {
                if (typeof err.response === 'undefined') {
                    SwalFailed (err.response.statusText)
                } else {
                    SwalWarning (err.response.data.message)
                }
            })
            .finally(() => {
                commit('SET_LOADING_LOGIN', false)
            })
    }
  }
}
export default moduleAuth
