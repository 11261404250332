import axios from 'axios'

export default function request_api () {
  const token = localStorage.getItem('token') || ''

  const http_axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 30000, // 30 detik
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${  token}`
    }
  })

  http_axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (typeof error.response === 'undefined' || error.response.status === 401) {
        localStorage.clear()
        window.document.location = '/'
      }
      return Promise.reject(error)
    }
  )

  return http_axios
}
