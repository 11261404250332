import API from '../../plugins/axios'
import store from '../store'
import {SwalSuccess, SwalInfo} from '@/plugins/sweetalert2'
const moduleShipment = {
    namespaced: true,
    state: {
        dataShipment: [],
        dataModal: {
            show: false,
            data: null,
            type: null
        },
        dataCargoType: [],
        dataConship: [],
        detailShipment: {},
        dataOffers: {},
        showPanelOffer: false
    },
    getters: {
        DATA_SHIPMENT (state) {
            return state.dataShipment
        }
    },
    mutations: {
        SET_PANEL_OFFER (state, payload) {
            state.showPanelOffer = payload
        },
        SET_DATA_SHIPMENT (state, payload) {
            state.dataShipment = payload
        },
        SET_ACTION_SHIPMENT (state, payload) {
            state.dataModal.show = payload.show
            state.dataModal.data = payload.data
            state.dataModal.type = payload.type
        },
        SET_CARGO_TYPE (state, payload) {
            state.dataCargoType = payload
        },
        SET_DETAIL_SHIPMENT (state, payload) {
            state.detailShipment = payload
        },
        SET_CONSHIP (state, payload) {
            state.dataConship = payload
        },
        SET_OFFER (state, payload) {
            state.dataOffers = payload
        }
    },
    actions: {
        LOAD_CARGO_TYPE ({commit}) {
            API.get('master/cargo-type/dropdown')
                .then((res) => {
                    commit('SET_CARGO_TYPE', res.data.data)
                })
                .catch((err) => {
                    console.log(err.response.data.message)
                })
        },
        LOAD_CONSHIP ({commit}) {
            API.get('master/shipperconsignee/dropdown')
                .then((res) => {
                    commit('SET_CONSHIP', res.data.data)
                })
                .catch((err) => {
                    console.log(err.response.data.message)
                })
        },
        CREATE_CONSHIP ({commit, dispatch}, payload) {
            const params ={
                name: payload
            }
            return new Promise ((resolve, reject) => {
                API.post('master/shipperconsignee/create', params)
                .then((res) => {
                    dispatch('LOAD_CONSHIP')
                    resolve (res)
                })
                .catch((err) => {
                    reject(err)
                    console.log(err.response.data.message)
                })
            })
        },
        LOAD_DETAIL_SHIPMENT ({commit}, payload) {
            const params = { id: payload }
            return new Promise((resolve, reject) => {
                API.get('transaction/shipment/detail', {params})
                    .then(res => {
                        commit('SET_DETAIL_SHIPMENT', res.data.data)
                        resolve(res)
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                        reject(err)
                    })
            })

        },
        LOAD_OFFER ({commit}, payload) {
            const params = {shipment: payload}
            commit('SET_OFFER', [])
            return new Promise ((resolve, reject) => {
                API.get('transaction/shipment/offers/bidder-list', {params})
                    .then((res) => {
                        commit('SET_OFFER', res.data.data)
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        DELETE_SHIPMENT ({commit}, payload) {
            const params = { id: payload.data.data.id, notes: payload.notes }
            store.commit('SET_LOADING')
            return new Promise((resolve, reject) => {
                API.delete('transaction/shipment/delete', {params})
                    .then((res) => {
                        SwalSuccess(res.data.message)
                        resolve(res)
                    })
                    .catch(err => {
                        SwalInfo(err.response.data.message)
                        reject()
                    })
                    .finally(() => store.commit('SET_LOADING'))
            })
        },
        CREATE_SHIPMENT ({commit}, payload) {
            store.commit('SET_LOADING')

            return new Promise ((resolve, reject) => {
                API.post('transaction/shipment/create', payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(err => {
                        SwalInfo(err.response.data.message)
                        reject(err)
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                        commit ('SET_ACTION_SHIPMENT', payload = {show: false, data: null, type: null })
                    })
            })
        },
        EDIT_SHIPMENT ({commit}, payload) {
            store.commit('SET_LOADING')
            return new Promise ((resolve, reject) => {
                API.patch('transaction/shipment/update', payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                        commit ('SET_ACTION_SHIPMENT', payload = {show: false, data: null, type: null })
                    })
            })
        },
        RENEW_SHIPMENT ({commit}, payload) {
            store.commit('SET_LOADING')
            return new Promise ((resolve, reject) => {
                API.patch('transaction/shipment/renew', payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(err => {
                        SwalInfo(err.response.data.message)
                        reject(err)
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                        commit ('SET_ACTION_SHIPMENT', payload = {show: false, data: null, type: null })
                    })
            })
        },
        REJECT_OFFER ({commit}, payload) {
            const params = {
                shipment: payload.data.data.shipment,
                id: payload.data.data.offer,
                notes: payload.notes
            }
            // console.log(params, payload)
            store.commit('SET_LOADING')
            return new Promise ((resolve, reject) => {
                API.patch('transaction/shipment/offers/reject', params)
                    .then((res) => {
                        API.patch('transaction/shipment/reject-to-mail', params)
                        resolve(res)
                    })
                    .catch(err => {
                        SwalInfo(err.response.data.message)
                        reject(err)
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                    })
            })
        },
        ACCEPT_OFFER ({commit}, payload) {
            const params = payload
            store.commit('SET_LOADING')
            return new Promise ((resolve, reject) => {
                API.patch('transaction/shipment/offers/accept', params)
                    .then((res) => {
                        API.patch('transaction/shipment/accept-to-mail', params)
                        resolve(res)
                    })
                    .catch(err => {
                        SwalInfo(err.response.data.message)
                        reject(err)
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                    })
            })
        },
        CONFIRM_SHIPMENT ({commit}, payload) {
            const params = payload
            store.commit('SET_LOADING')
            return new Promise ((resolve, reject) => {
                API.patch('transaction/shipment/confirm-complete', params)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch(err => {
                        SwalInfo(err.response.data.message)
                        reject(err)
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                    })
            })
        }
    }
}
export default moduleShipment
