import {icon} from 'leaflet'
import API from '../../plugins/axios'
import store from '../store'
import {SwalSuccess, SwalFailed} from '@/plugins/sweetalert2'

const modulePort = {
    namespaced: true,
    state: {
        dataPort: [],
        portMarkers: {
            data: {},
            show: false,
            name:''
        },
        dataModal: {
            show: false,
            data: null,
            type: null
        },
        detailPort: {},
        icon: icon({
            iconUrl: '/images/icon_marker_map.png',
            iconSize: [32, 37],
            iconAnchor: [16, 37]
        }),
        pagination: {
            startPage: 0,
            lengthPage: 50,
            currentPage: 1,
            perPage: null,
            totalRecords: null
        },
        dropdown: []
    },
    getters: {
        GET_DATA_PORT (state) {
            return  state.dataPort
        },
        GET_MARKERS (state) {
            return  state.portMarkers
        }
    },
    mutations: {
        SET_DATA_PORT (state, payload) {
            state.dataPort = payload
        },
        SET_ACTION_PORT (state, payload) {
            state.dataModal.show = payload.show
            state.dataModal.data = payload.data
            state.dataModal.type = payload.type
        },
        SET_DROPDOWN (state, payload) {
            state.dropdown = payload
        },
        SET_PORT_MARKER (state, payload) {
            if (payload) {
                state.portMarkers.data = payload
                state.portMarkers.show = true
            } else {
                state.portMarkers.data = payload
                state.portMarkers.show = false
            }
        },
        SET_DETAIL_PORT (state, payload) {
            state.detailPort = payload
        }
    },
    actions: {
        LOAD_DROPDOWN ({commit}) {
            API.get('master/port/dropdown')
                .then(res => {
                    commit('SET_DROPDOWN', res.data.data)
                })
                .catch(err => {
                    console.log(err.response.data.message)
                })
        },
        LOAD_DETAIL_PORT ({commit}, payload) {
            const params = { id: payload }
            store.commit('SET_LOADING')
            return new Promise((resolve, reject) => {
                API.get('master/port/detail', {params})
                    .then(res => {
                        commit('SET_DETAIL_PORT', res.data.data)
                        resolve()
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                        reject()
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                    })
            })
        },
        CREATE_PORT ({commit}, payload) {
            store.commit('SET_LOADING')
            return new Promise((resolve, reject) => {
                API.post('master/port/create', payload)
                    .then(res => {
                        SwalSuccess(res.data.message)
                        resolve(res)
                    })
                    .catch(err => {
                        SwalFailed(err.response.data.message)
                        reject()
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                        commit('SET_ACTION_PORT', {show: false, data: null, type: null})
                    })
            })

        },
        EDIT_PORT ({commit}, payload) {
            store.commit('SET_LOADING')
            return new Promise((resolve, reject) => {
                API.patch('master/port/update', payload)
                    .then(res => {
                        SwalSuccess(res.data.message)
                        resolve()
                    })
                    .catch(err => {
                        SwalFailed(err.response.data.message)
                        reject()
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                        commit('SET_ACTION_PORT', {show: false, data: null, type: null})
                    })
            })

        },
        DELETE_PORT ({commit}, payload) {
            const params = {id: payload}
            store.commit('SET_LOADING')
            return new Promise((resolve, reject) => {
                API.delete('master/port/delete', {params})
                    .then(res => {
                        SwalSuccess(res.data.message)
                        resolve()
                    })
                    .catch(err => {
                        SwalFailed(err.response.data.message)
                        reject()
                    })
                    .finally(() => {
                        store.commit('SET_LOADING')
                        store.commit('RESET_BOUND')
                        commit('SET_ACTION_PORT', {show: false, data: null, type: null})
                    })
            })
        }

    }
}

export default  modulePort
